@import '../../styles/base';

.paginationContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
  text-align: center;

  &.top {
    margin-top: -16px;
  }

  .right {
    display: flex;
    align-items: center;

    > span {
      font-size: 14px;
      color: $text;
    }
  }

  button {
    margin: 0 8px 0 16px;
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  border: 1px solid rgba(#999, 0.3);
  padding: 0;
  border-radius: 4px;
  overflow: hidden;

  > li {
    background: #fff;

    &:not(:last-child) {
      border-right: 1px solid rgba(#999, 0.3);
    }

    &:first-child {
      border-right: none;
    }

    &:nth-child(2) {
      border-left: 1px solid rgba(#999, 0.3);
    }

    $_cell-size: 36px;

    > a {
      user-select: none;
      color: #0064d2;
      display: block;
      width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      cursor: pointer;
      letter-spacing: -0.12px;
      font-size: 14px;
      position: relative;
    }

    &:global(.disabled) {
      // display: none;
      filter: grayscale(100%);
      opacity: 0.3;
      pointer-events: none;
    }

    &:global(.previous),
    &:global(.next) {
      > a {
        width: 50px;
      }
    }

    &:global(.next) {
      > a {
        &::before {
          transform: rotate(180deg) translate(50%, 50%) scale(0.8);
        }
      }
    }

    // &:hover,
    &:global(.active) {
      background: #effaff;
    }
  }
}
