@import '../../styles/base';

.no-result {
  text-align: center;
  padding-top: 8px;

  h4 {
    font: bold 16px/24px $font-family;
    letter-spacing: -0.17px;
    margin-bottom: 8px;
  }

  p {
    font: 400 16px/24px $font-family;
  }
}
