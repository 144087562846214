@import './vars';
@import './placeholders';

.react-datetime-picker--disabled,
*:disabled {
  pointer-events: none;
  cursor: not-allowed;
}

input,
textarea {
  width: 100%;
  display: block;
  font-size: 16px;
  padding: 0 8px;
}

button {
  border: none;
  cursor: pointer;
}

textarea {
  border: 1px solid #000;
  border-radius: 4px;
  padding: 8px;
}

input {
  &[type='text'],
  &[type='password'],
  &[type='number'],
  &[type='search'] {
    box-sizing: border-box;
    appearance: none;
    border-radius: 4px;
    border: 1px solid #000;
    transition: border-color 0.3s ease-in-out;
    font: 400 16px/38px $font-family;
    padding: 0 16px;

    &:focus {
      border-color: $form-stroke-active;
    }
  }

  &[type='submit'],
  &[type='search'] {
    border: none;
    cursor: pointer;
  }
}

::placeholder {
  color: #9c9c9c;
}

.datetime-picker-separator {
  display: inline-block;
  margin: 0 4px;
}

.react-datetime-picker {
  input {
    padding: unset;
  }

  *[class*='leadingZero'] {
    margin-right: 9px;
  }
}

.react-datetime-picker__wrapper {
  border-radius: 4px;
  border: 1px solid #000;
  font: 16px/32px $font-family;
  height: 40px;
  padding: 0 16px;
}

.react-datetime-picker__inputGroup {
  display: flex;
  vertical-align: baseline;
}

.react-datetime-picker__inputGroup__leadingZero {
  padding: 2px 0;
}

.select {
  position: relative;
  border: 1px solid rgb(79, 79, 79);
  border-radius: 6px;
  color: rgb(79, 79, 79);
  width: 248px;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 10px solid #4f4f4f;
    right: 15px;
    pointer-events: none;
    @extend %vertical-center;
  }

  select {
    appearance: none;
    display: block;
    border: none;
    padding: 0 16px;
    width: 100%;
    font: 400 14px/38px $font-family;
    background-color: #fff;
  }
}

.react-datetime-picker input {
  &[type='number'] {
    appearance: textfield;
    border: none;
  }
}

label {
  @extend %caption-text;
}

.radio-group {
  display: flex;
  align-items: center;
  height: 40px;

  label {
    position: relative;
    cursor: pointer;

    &:not(:last-child) {
      &::after {
        content: '';
        width: 1px;
        height: 16px;
        right: -1px;
        background: #e5e5e5;
        @extend %vertical-center;
      }
    }

    .radio-label-text {
      display: inline-block;
      color: #fff;
      padding: 0 16px;
      border-radius: 16px;
      white-space: nowrap;
      color: #7e7e7e;
      /* start of visual hack */
      font: 400 14px/22px $font-family;
      height: 24px;
      /* end of visual hack */
    }

    input[type='radio'] {
      appearance: none;
      position: absolute;

      &:checked {
        + .radio-label-text {
          font-weight: bold;
          color: #fff;
          background: $dark-navy;
          margin: 0 16px;
        }
        &:disabled {
          + .radio-label-text {
            background: $form-disabled;
          }
        }
      }
    }

    &:first-of-type .radio-label-text,
    &:first-of-type input[type='radio']:checked + .radio-label-text {
      margin-left: 0;
    }
  }
}

textarea {
  height: 100%;
}

button {
  border: 0;
  cursor: pointer;
  appearance: none;
}
