@import '../../styles/base';

.search-form {
  margin: 18px 8px 0;

  .top {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .top-label {
      font-weight: bold;
      display: inline-block;
      width: 64px;
    }
  }

  .bottom {
    display: flex;
    margin-left: 64px;
    align-items: center;
    justify-content: space-between;

    > *:nth-child(2):not(:last-child) {
      flex: 1;
      margin: 0 8px;
    }
  }
}
