@import '../../styles/base';

.table {
  // table-layout: fixed;
  border: none;
  border-radius: 6px;
  width: 100%;
  color: $table-text;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);

  thead {
    th {
      border: 0;
      padding: 0 0 0 32px;
      letter-spacing: -0.1px;
      height: 64px;
      text-align: left;
      vertical-align: middle;
      font: bold 16px/24px $font-family;
      color: $table-label;
      background-color: $table-header-bg;

      &:global(.align-right) {
        padding: 0 32px 0 0;
      }
    }
  }

  tbody {
    td {
      border: none;
      border-top: 1px solid $dividing;
      padding: 16px 0 16px 32px;
      min-height: 64px;
      vertical-align: top;
      font-family: $font-family-mono;

      &:global(.align-right) {
        padding: 16px 32px 16px 0;
      }

      time {
        font-size: 15px;
        letter-spacing: -0.7px;
      }
    }

    tr {
      &:hover {
        td {
          background-color: $table-item-hover;
        }
      }
    }
  }
}
