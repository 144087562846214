@import '../../styles/base';

.main-nav {
  padding-left: 116px;
  white-space: nowrap;

  a {
    position: relative;
    display: inline-block;
    margin: 0 24px;
    color: #fff;
    opacity: 0.3;
    font: bold 14px/72px $font-family;
    @extend %text-ellipsis;

    &::after {
      transition: width 0.2s ease-in-out;
      content: '';
      position: absolute;
      width: 0;
      bottom: 14px;
      border-radius: 2px;
      height: 4px;
      background-color: #ff6262;
      @extend %horizontal-center;
    }

    &:hover,
    &.active {
      opacity: 1;

      &::after {
        width: 76.5%;
      }
    }
  }
}
