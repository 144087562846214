@import '../../styles/base';

.block-root {
  margin: 120px auto;
  width: 460px;
  text-align: center;
}

.block-form {
  border-radius: 6px;
  padding: 32px 24px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(#000, 0.08);

  h1 {
    margin-bottom: 40px;
    font-size: 18px;
    font-weight: bold;
  }

  fieldset {
    margin-top: 12px;
  }
}

.copy {
  margin-top: 8px;
  font-size: 14px;
}
