/**
 * Variables
 */
$font-family: 'Noto Sans JP', Roboto, -apple-system, BlinkMacSystemFont,
  'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
$font-family-mono: 'PT Mono', monospace;
$min-width: 984px;
$mobile-header-height: 46px;
$header-height: 74px;

$max-width: 1024px;
$font-weight-map: (
  thin: 100,
  extra-light: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semi-bold: 600,
  bold: 700,
  extra-bold: 800,
  black: 900,
);

$main-header-color: #505050;

$text: #4c4c4c;
$dark-navy: #384967;

$status-red: #ff6262;
$status-red-bg: #fff4f4;
$status-blue: #4a92d0;
$status-blue-bg: #dff0ff;
$status-green: #8bc34a;
$status-green-bg: #f6ffeb;

$table-label: #496792;
$table-header-bg: #f5f8fc; // #f2f5fc alpha 0.7
$table-text: #666666;
$table-item-hover: #edf7ff; // #9fd6ff alpha 0.2
$table-item-alt: #f6f8fa;

$dividing: #e3e7ee;

$form-disabled: #bcbcbc;
$form-stroke-active: #58789c;
$form-storke-disabled: #bcbcbc;
