@import '../../styles/base';

.payment_info {
  margin-top: 12px;

  .meta {
    display: flex;
    justify-content: flex-start;

    div {
      &:not(:last-child) {
        margin-right: 100px;
      }

      .label {
        display: block;
        margin-bottom: 4px;
        font-size: 14px;
        font-weight: bold;
        color: $table-label;
      }

      .value {
        display: block;
        margin-bottom: 0;
        font-size: 24px;
      }
    }
  }
}

.extra {
  margin-top: 16px;
}

.back-button {
  padding-left: 24px;
  padding-right: 24px;
  width: auto;
}

.rejection-code {
  margin-left: 0;
}
