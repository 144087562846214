@import './vars';

@media only screen and (max-width: 420px) {
}

// iPad
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
}

%caption-text {
  font: 400 13px/1 $font-family;
  color: #070707;
}

%body-text {
  font: 400 16px/24px $font-family;
  color: $text;
}

%body-center-text {
  @extend %body-text;
}

%subtitle-text {
  font: bold 20px/24px $font-family;
  color: $text;
}

%placeholder-text {
  color: #586065;
  font: 400 16px/40px $font-family;
}

%btn-circle {
  display: block;
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 32px;
  height: 32px;
  background-color: transparent;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
}

%btn-circle-edit,
%btn-circle-delete {
  @extend %btn-circle;
}

%box-shadow {
  box-shadow: 0 4px 24px -8px rgba(#000000, 0.32);
}
