@import '../../styles/base';

#header {
  display: flex;
  height: 72px;
  color: #fff;
  background-color: $dark-navy;

  h1 {
    padding: 20px 32px;
  }
}
