body {
  background-color: #F2F5FC;
}

.root {
  height: 100vh;
}

.main {
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}
