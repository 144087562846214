@import '../../styles/base';

.reports {
  display: flex;
}

.reportBlock {
  margin: 0 16px 16px 0;
  border-radius: 6px;
  padding: 24px;
  width: 360px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.08);

  h3 {
    margin-bottom: 16px;
    font-size: 16px;
    color: $table-label;
  }

  p {
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 14px;
  }

  div {
    margin-top: 16px;
    padding-top: 4px;
  }
}
