@import '../../styles/base';

.login-root {
  margin: 120px auto;
  width: 460px;
  text-align: center;
}

.login-form {
  border-radius: 6px;
  padding: 32px 24px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);

  h1 {
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: bold;
  }

  fieldset {
    margin-top: 12px;
  }

  input[type='submit'] {
    width: 100%;
  }
}

.login-error-msg {
  margin-top: 8px;
  margin-bottom: 16px;
  height: 24px;
  font-size: 16px;
  color: $status-red;
}

.copy {
  margin-top: 8px;
  font-size: 14px;
}
