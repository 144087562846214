@import '../../styles/base';

.session-panel {
  margin-left: auto;

  b,
  button {
    padding-left: 40px;
    padding-right: 40px;
    height: 72px;
    font-size: 14px;
    line-height: 72px;
    color: #fff;
    background: none;
  }

  b {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    padding-right: 56px;
  }

  button {
    position: relative;
    border-left: 1px solid rgba(255, 255, 255, 0.1);

    &::after {
      transition: width 0.2s ease-in-out;
      content: '';
      position: absolute;
      width: 0;
      bottom: 14px;
      border-radius: 2px;
      height: 4px;
      background-color: #ff6262;
      @extend %horizontal-center;
    }

    &:hover::after {
      width: 26.5%;
    }
  }

  
}
